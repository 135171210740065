.panel {
    background: #fff;
  }
  .icon {
    float: right;
    height: 48px;
  }
  .panelBody {
    padding: 12px;
  }
  hr {
    margin: 0 0 10px 0;
  }
  .heading {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #2e384d;
  }
  .description1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    color: #2e384d;
  }
  .description2 {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #606265;
  }
  .button {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: bold;
    float: right;
    margin-right: 16px;
    margin-bottom: 16px;
    width: 115px;
    height: 42px;
    background: #3489ec;
    border-radius: 3px;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 4px;
  }
  .download {
    float: right;
    margin-top: 50px;
    left: 45px;
    position: relative;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    color: #3489EC;
    cursor: pointer;
  }
  